import React from "react";
import { IEventProps } from "../../types/IEventProps";
import { Button, Column, Columns, Stack, Text } from "@origin-digital/ods-core";
import { navTo } from "@origin-digital/event-dispatcher";
import { CelebrationIcon } from "../../components/CelebrationIcon";

export const Success = (props: IEventProps) => {
  const { event } = props;

  const navigateToFEHPage = () => {
    navTo.freeEnergyHour();
  };

  return (
    <Stack space="medium" data-id="spike-widget-v2-success">
      <Columns alignY="center">
        <Column>
          <Stack space="xsmall">
            <Text tone="neutralDark" weight="medium">
              You earned an Energy Happy Hour!
            </Text>
            <Text>
              During the SpikeHour on {event.date} you used {event.actualUsage}{" "}
              kWh, which is less than your energy goal of{" "}
              {event.postEventBaseline} kWh. Great job!
            </Text>
          </Stack>
        </Column>
        <Column width="content">
          <CelebrationIcon />
        </Column>
      </Columns>
      <Button variant="outlined" onClick={navigateToFEHPage}>
        Book your Free Energy Hour
      </Button>
    </Stack>
  );
};
