import { DividerCard, Stack } from "@origin-digital/ods-core";
import React from "react";
import { IDashboardPageData, IWidgetVersion } from "../contract";
import { Header } from "./Header";
import { Body } from "./Body";
import { StatusCard } from "./StatusCard/StatusCard";

export interface SpikeWidgetContentProps {
  data: IDashboardPageData;
}

export const SpikeWidgetContent = (props: SpikeWidgetContentProps) => {
  const { data } = props;

  const title: string | undefined =
    data.widgetVersion === IWidgetVersion.IN_PROGRESS
      ? "In Progress"
      : undefined;

  return (
    <StatusCard title={title} tone="positive">
      <DividerCard data-id="spike-widget-v2-body">
        <Stack space="medium" dividers>
          <Header />
          <Body data={data} />
        </Stack>
      </DividerCard>
    </StatusCard>
  );
};
