import { hasInternetAccountInActiveOrSetupStatus } from "../../../../util/account";
import { WidgetDefinition } from "../../../WidgetDefinition";
import { BroadbandWidget, IBroadbandWidgetProps } from "./index";

export const broadbandWidgetDefinition: WidgetDefinition<IBroadbandWidgetProps> =
  {
    widgetComponent: BroadbandWidget,
    name: "BroadbandWidget",
    shouldLoad: (data) => hasInternetAccountInActiveOrSetupStatus(data),
    propsBuilder: () => ({
      backendUrl: TAL.config.broadband.superloopLink,
    }),
  };
