import React from "react";

export const CelebrationIcon = () => {
  return (
    <img
      src="https://www.originenergy.com.au/cdn/images/originenergy/image/upload/pictograms/pictogram_celebration.svg"
      alt=""
      width="72"
      height="72"
      data-id="celebration-icon"
    />
  );
};
