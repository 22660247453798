import React from "react";

export const CloudyIcon = () => (
  <svg
    width="21px"
    height="14px"
    viewBox="0 0 21 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    data-id="weather-icon-cloud"
  >
    <title>Cloudy</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-811.000000, -1451.000000)">
        <g transform="translate(809.000000, 1446.000000)">
          <g id="cloud">
            <rect id="Rectangle" x="0" y="0" width="24" height="24" />
            <path
              d="M18.5155273,10.1552344 C17.9351367,7.21060547 15.3489844,5 12.2421875,5 C9.77552734,5 7.63320312,6.39976562 6.56630859,8.44820312 C3.99722656,8.72132812 2,10.897793 2,13.5351562 C2,16.360293 4.29595703,18.65625 7.12109375,18.65625 L18.2167969,18.65625 C20.5725,18.65625 22.484375,16.744375 22.484375,14.3886719 C22.484375,12.1353906 20.734668,10.3088672 18.5155273,10.1552344 Z M18.2167969,16.9492188 L7.12109375,16.9492188 C5.23482422,16.9492188 3.70703125,15.4214258 3.70703125,13.5351562 C3.70703125,11.6488867 5.23482422,10.1210938 7.12109375,10.1210938 L7.72708984,10.1210938 C8.29041016,8.14947266 10.0913281,6.70703125 12.2421875,6.70703125 C14.836875,6.70703125 16.9365234,8.80667969 16.9365234,11.4013672 L16.9365234,11.828125 L18.2167969,11.828125 C19.6336328,11.828125 20.7773438,12.9718359 20.7773438,14.3886719 C20.7773438,15.8055078 19.6336328,16.9492188 18.2167969,16.9492188 Z"
              id="Shape"
              fill="#FF9F00"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
