import React from "react";
import { NoEvents } from "../EventWidgetVersions/NoEvents/NoEvents";
import { IDashboardPageData, IWidgetVersion } from "../contract";
import { Finished, Invite, InProgress, OptedIn } from "../EventWidgetVersions";
import { Success } from "../EventWidgetVersions/Success/Success";
import { Failure } from "../EventWidgetVersions/Failure/Failure";

interface IBodyProps {
  data: IDashboardPageData;
}

export const Body = (props: IBodyProps) => {
  const { widgetVersion, event } = props.data;

  switch (widgetVersion) {
    case IWidgetVersion.FINISHED:
      return <Finished />;
    case IWidgetVersion.INVITE:
      return event ? <Invite event={event} /> : null;
    case IWidgetVersion.OPTED_IN:
      return event ? <OptedIn event={event} /> : null;
    case IWidgetVersion.IN_PROGRESS:
      return event ? <InProgress event={event} /> : null;
    case IWidgetVersion.SUCCESS:
      return event ? <Success event={event} /> : null;
    case IWidgetVersion.FAILURE:
      return event ? <Failure event={event} /> : null;
    default:
      return <NoEvents />;
  }
};
