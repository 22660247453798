// @ts-nocheck
import { dapiFetchCache, dapiFetch, IDapiRequest } from '@origin-digital/daxi';

import {
  IDashboardPageResponse,
  IErrorResponseDto,
  IEventPageResponse,
} from '../models';

/*
 * This code has been generated from an OpenAPI spec.
 */

export interface IGetDashboardPageRequest {
}

/**

 */
export const getDashboardPage = async (baseUrl: string,  authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IDashboardPageResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getDashboardPageUrl();
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IDashboardPageResponse>(url + queryString, fetchOptions);
          return response.data as IDashboardPageResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IDashboardPageResponse>(url + queryString, fetchOptions);
        return response.data as IDashboardPageResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getDashboardPageUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getDashboardPage endpoint url that substitutes in given url params.
  */
const getDashboardPageUrl = () => {
    return '/api/v1/dashboard-page';
};


export interface IGetEventPageRequest {
  eventId: number;
}

/**

 */
export const getEventPage = async (baseUrl: string, data: IGetEventPageRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IEventPageResponse> => {
    try {
        const method = 'GET';
        const url = baseUrl + getEventPageUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IEventPageResponse>(url + queryString, fetchOptions);
          return response.data as IEventPageResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IEventPageResponse>(url + queryString, fetchOptions);
        return response.data as IEventPageResponse;
    } catch (error) {
        error.newRelic = `'GET: ${ getEventPageUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getEventPage endpoint url that substitutes in given url params.
  */
const getEventPageUrl = (data: IGetEventPageRequest) => {
    return '/api/v1/event-page/{eventId}'.replace('{' + 'eventId' + '}', encodeURIComponent(data.eventId));
};


export interface IGetHealthRequest {
}

/**

 */
export const getHealth = async (baseUrl: string,  authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<void> => {
    try {
        const method = 'GET';
        const url = baseUrl + getHealthUrl();
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<void>(url + queryString, fetchOptions);
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<void>(url + queryString, fetchOptions);
    } catch (error) {
        error.newRelic = `'GET: ${ getHealthUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the getHealth endpoint url that substitutes in given url params.
  */
const getHealthUrl = () => {
    return '/health';
};


export interface IM2mRequest {
}

/**

 */
export const m2m = async (baseUrl: string,  authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<void> => {
    try {
        const method = 'GET';
        const url = baseUrl + m2mUrl();
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<void>(url + queryString, fetchOptions);
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<void>(url + queryString, fetchOptions);
    } catch (error) {
        error.newRelic = `'GET: ${ m2mUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the m2m endpoint url that substitutes in given url params.
  */
const m2mUrl = () => {
    return '/api/v1/m2m';
};


export interface IOptInEventRequest {
  eventId: number;
}

/**

 */
export const optInEvent = async (baseUrl: string, data: IOptInEventRequest, authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<IEventPageResponse> => {
    try {
        const method = 'PATCH';
        const url = baseUrl + optInEventUrl(data);
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<IEventPageResponse>(url + queryString, fetchOptions);
          return response.data as IEventPageResponse;
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<IEventPageResponse>(url + queryString, fetchOptions);
        return response.data as IEventPageResponse;
    } catch (error) {
        error.newRelic = `'PATCH: ${ optInEventUrl(data) }'`;
        throw error;
    }
};

/**
  * Generate a getter for the optInEvent endpoint url that substitutes in given url params.
  */
const optInEventUrl = (data: IOptInEventRequest) => {
    return '/api/v1/event-page/{eventId}/opt-in'.replace('{' + 'eventId' + '}', encodeURIComponent(data.eventId));
};


export interface IUnauthRequest {
}

/**

 */
export const unauth = async (baseUrl: string,  authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<void> => {
    try {
        const method = 'GET';
        const url = baseUrl + unauthUrl();
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<void>(url + queryString, fetchOptions);
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<void>(url + queryString, fetchOptions);
    } catch (error) {
        error.newRelic = `'GET: ${ unauthUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the unauth endpoint url that substitutes in given url params.
  */
const unauthUrl = () => {
    return '/api/v1/unauth';
};


export interface IUserRequest {
}

/**

 */
export const user = async (baseUrl: string,  authorization?: IDapiRequest["authorization"], ttlInMin: number = 0): Promise<void> => {
    try {
        const method = 'GET';
        const url = baseUrl + userUrl();
        const queryString = '';

        if (ttlInMin > 0) {
          const fetchOptions = { method, authorization, ttlInMin };
          const response = await dapiFetchCache<void>(url + queryString, fetchOptions);
        }

        const fetchOptions = { method, authorization };
        const response = await dapiFetch<void>(url + queryString, fetchOptions);
    } catch (error) {
        error.newRelic = `'GET: ${ userUrl() }'`;
        throw error;
    }
};

/**
  * Generate a getter for the user endpoint url that substitutes in given url params.
  */
const userUrl = () => {
    return '/api/v1/user';
};

