import { createGlobalStyle } from "@origin-digital/sc-interop";

export const GlobalStyles = createGlobalStyle`
    // Vertical align the content and the preloader
    #oetal-application-dev-plugin,
    #oetal-application-root {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
`;
