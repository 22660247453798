import React from "react";

export const SunnyIcon = () => (
  <svg
    width="22px"
    height="22px"
    viewBox="0 0 22 22"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    data-id="weather-icon-sun"
  >
    <defs>
      <path
        id="path-1"
        d="M5.76,4.29 L3.96,2.5 L2.55,3.91 L4.34,5.7 L5.76,4.29 Z M0,10 L3,10 L3,12 L0,12 L0,10 Z M10,0 L12,0 L12,3 L10,3 L10,0 Z M18.04,2.495 L19.448,3.902 L17.658,5.692 L16.251,4.284 L18.04,2.495 Z M16.24,17.61 L18.03,19.41 L19.44,18 L17.64,16.21 L16.24,17.61 Z M19,10 L22,10 L22,12 L19,12 L19,10 Z M11,5 C7.69,5 5,7.69 5,11 C5,14.31 7.69,17 11,17 C14.31,17 17,14.31 17,11 C17,7.69 14.31,5 11,5 Z M11,14.95 C8.79,14.95 7,13.16 7,10.95 C7,8.74 8.79,6.95 11,6.95 C13.21,6.95 15,8.74 15,10.95 C15,13.16 13.21,14.95 11,14.95 Z M10,19 L12,19 L12,22 L10,22 L10,19 Z M2.55,17.99 L3.96,19.4 L5.75,17.6 L4.34,16.19 L2.55,17.99 Z"
      />
    </defs>
    <title>Sunny</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-810.000000, -1368.000000)">
        <g transform="translate(809.000000, 1367.000000)">
          <g>
            <g>
              <g id="Mask" transform="translate(1.000000, 1.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <use fill="#000000" fillRule="nonzero" xlinkHref="#path-1" />
                <g mask="url(#mask-2)">
                  <g
                    transform="translate(-3.000000, -2.000000)"
                    id="Shape-/-Box-Filled-Override-/-01-Primary-/-Yellow"
                  >
                    <g>
                      <polygon
                        id="Rectangle"
                        fill="#FF9F00"
                        points="0 0 28 0 28 28 0 28"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
