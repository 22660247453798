import React from "react";
import { Inline, Text } from "@origin-digital/ods-core";
import { IconCheck } from "@origin-digital/ods-icons";

export const ChargeComplete = () => (
  <Inline alignY="center" space="xxsmall">
    <IconCheck color="positive" size="small" />
    <Text variant="body-small">Charge complete</Text>
  </Inline>
);
