import React from "react";
import { useVehiclesData } from "../../provider/VehiclesProvider";
import { Card, Inline, Strong, Text } from "@origin-digital/ods-core";
import { convertToDateMonth } from "../../utils/dateHelper";
import { roundCurrencyHalfUp } from "../../utils/formatHelpers";
import { IconPiggybank } from "@origin-digital/ods-icons";

export const ChargeCostPanel = () => {
  const { chargeCost } = useVehiclesData();

  if (!chargeCost?.isDisplayed) {
    return null;
  }
  const { dateFrom, dateTo, credit } = chargeCost;
  const formattedCredit = roundCurrencyHalfUp(credit);

  return (
    <Card
      data-id="charge-cost-option"
      paddingXSpecific="medium"
      paddingYSpecific="medium"
      backgroundColor="positive"
    >
      <Inline alignY="center" space="small" disableWrap>
        <IconPiggybank color="neutralLight" size="xsmall" />
        <Text variant="body-small" tone="neutralDark">
          Latest savings <Strong>{formattedCredit}</Strong> (
          {convertToDateMonth(dateFrom)} to {convertToDateMonth(dateTo)})
        </Text>
      </Inline>
    </Card>
  );
};
