import React from "react";
import { Text } from "@origin-digital/ods-core";

export const NoEvents = () => {
  return (
    <Text data-id="spike-widget-v2-no-events">
      No upcoming SpikeHour at this time.
    </Text>
  );
};
