import React, { useContext } from "react";
import { useSpikeEventData } from "./useSpikeEventData";
import { IDashboardPageData } from "../contract";

export interface ISpikeEventDataProviderContext {
  loading?: boolean;
  error?: any;
  data?: IDashboardPageData;
  reload: () => void;
}

export const SpikeEventDataProviderContext =
  React.createContext<ISpikeEventDataProviderContext>({
    loading: true,
    reload: () => {},
  });

export const useSpikeDataContext = () =>
  useContext(SpikeEventDataProviderContext);

interface IProviderProps {
  children: React.ReactNode;
}

export const SpikeEventDataProvider = ({ children }: IProviderProps) => {
  const { data, loading, error, reload } = useSpikeEventData();

  return (
    <SpikeEventDataProviderContext.Provider
      value={{ data, loading, error, reload }}
    >
      {children}
    </SpikeEventDataProviderContext.Provider>
  );
};
