import {
  DigitalAccountStatus,
  DigitalAccountType,
} from "../graphql-types/rx-gateway/globalTypes";
import {
  UserAccounts_viewer_digital,
  UserAccounts_viewer_digital_accounts,
} from "../graphql-types/rx-gateway/UserAccounts";

export function hasActiveAccount(
  data: UserAccounts_viewer_digital,
  accountType: DigitalAccountType
) {
  return data.accounts.some(
    (account: UserAccounts_viewer_digital_accounts) =>
      account.type === accountType &&
      account.status === DigitalAccountStatus.ACTIVE
  );
}

export function hasInternetAccountInActiveOrSetupStatus(
  data: UserAccounts_viewer_digital
) {
  //Check if there is a list of Superloop accounts in active or setup status
  return data.accounts.some(
    (account: UserAccounts_viewer_digital_accounts) =>
      account.type === DigitalAccountType.SUPERLOOP &&
      (account.status === DigitalAccountStatus.ACTIVE ||
        account.status === DigitalAccountStatus.SETUP)
  );
}

export function hasAccountInActiveOrSetupStatus(
  data: UserAccounts_viewer_digital,
  accountType: DigitalAccountType
) {
  return data.accounts.some(
    (account) =>
      account.type === accountType &&
      (account.status === DigitalAccountStatus.ACTIVE ||
        account.status === DigitalAccountStatus.SETUP)
  );
}

export function hasAccount(
  data: UserAccounts_viewer_digital,
  accountType: DigitalAccountType
) {
  return data.accounts.some((account) => account.type === accountType);
}

export const getOpenSapAccounts = (data: UserAccounts_viewer_digital) => {
  return data.accounts.filter(
    (it) =>
      it.type === DigitalAccountType.SAP &&
      it.status === DigitalAccountStatus.ACTIVE
  );
};

export function hasAccountOnly(
  data: UserAccounts_viewer_digital,
  accountType: DigitalAccountType
) {
  return data.accounts.every(
    (account) =>
      account.type === accountType &&
      account.status === DigitalAccountStatus.ACTIVE
  );
}
