export interface IConfig {
  widgetName: string;
}

export const config: IConfig = {
  widgetName: "spike-widget-v2",
};

// Temporary hack until we enable widget config
export const isProd = () => (window as any)?.oetal?.env?.name === "prod";
