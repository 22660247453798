/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  DORMANT = "DORMANT",
  ENROLMENT_ERROR = "ENROLMENT_ERROR",
  ENROLMENT_REJECTED = "ENROLMENT_REJECTED",
  INCOMPLETE = "INCOMPLETE",
  PENDING = "PENDING",
  VOID = "VOID",
  WITHDRAWN = "WITHDRAWN",
}

export enum DigitalAccountStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  INCOMPLETE = "INCOMPLETE",
  SETUP = "SETUP",
  UNKNOWN = "UNKNOWN",
}

/**
 * Represents a type of origin system this account belongs to.
 */
export enum DigitalAccountType {
  BROADBAND = "BROADBAND",
  KRAKEN = "KRAKEN",
  LPG = "LPG",
  OHM = "OHM",
  SAP = "SAP",
  SUPERLOOP = "SUPERLOOP",
  UNKNOWN = "UNKNOWN",
  VPP = "VPP",
}

export enum DigitalServiceStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  SETUP = "SETUP",
  UNKNOWN = "UNKNOWN",
}

export enum DigitalServiceType {
  CES_ELECTRICITY = "CES_ELECTRICITY",
  CES_ELECTRICITY_APPLIANCE = "CES_ELECTRICITY_APPLIANCE",
  CES_GAS_APPLIANCE = "CES_GAS_APPLIANCE",
  COMMUNITY_BATTERY = "COMMUNITY_BATTERY",
  ELECTRICITY = "ELECTRICITY",
  ESAAS = "ESAAS",
  EV_CHARGER = "EV_CHARGER",
  GAS = "GAS",
  HOT_WATER = "HOT_WATER",
  LPG_EXCHANGE = "LPG_EXCHANGE",
  LPG_EXCHANGE_METERED = "LPG_EXCHANGE_METERED",
  LPG_INSITU = "LPG_INSITU",
  LPG_INSITU_METERED = "LPG_INSITU_METERED",
  LPG_METERED = "LPG_METERED",
  LPG_TERMINAL = "LPG_TERMINAL",
  NBN = "NBN",
  OHM = "OHM",
  OPTICOMM = "OPTICOMM",
  SOLAR = "SOLAR",
  UNKNOWN = "UNKNOWN",
  UNMETERED_ELECTRICITY = "UNMETERED_ELECTRICITY",
  UNMETERED_GAS = "UNMETERED_GAS",
  VOIP = "VOIP",
  VPP_BATTERY = "VPP_BATTERY",
}

export enum DigitalUserCustomerType {
  MULTI_SITE_PORTAL = "MULTI_SITE_PORTAL",
  RESIDENTIAL = "RESIDENTIAL",
  SMALL_MEDIUM_ENTERPRISE = "SMALL_MEDIUM_ENTERPRISE",
}

export enum FeatureStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum FeatureType {
  BATTERY = "BATTERY",
  BATTERY_MAXIMISER = "BATTERY_MAXIMISER",
  BIZA = "BIZA",
  BRAZE = "BRAZE",
  EV_ICHARGE = "EV_ICHARGE",
  INTERCOM = "INTERCOM",
  OHM = "OHM",
  REWARDS_FEH = "REWARDS_FEH",
  SPIKE = "SPIKE",
  UNKNOWN = "UNKNOWN",
}

export enum ServiceType {
  ELECTRICITY = "ELECTRICITY",
  EMBEDDED_ELECTRICITY = "EMBEDDED_ELECTRICITY",
  EMBEDDED_HOT_WATER = "EMBEDDED_HOT_WATER",
  GAS = "GAS",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
