import React from "react";
import { TrackingProvider } from "@origin-digital/ods-core";
import {
  titlePrefix,
  trackingProviderCaptureClickCard,
} from "@origin-digital/widget-helpers";
import { ICardAA } from "@origin-digital/reporting-client";
import { Loader } from "./Loader";
import {
  useWidgetProvider,
  WidgetStatusCode,
} from "@origin-digital/widget-provider";
import { SpikeWidgetContent } from "./SpikeWidgetContent";
import { Error } from "./Error";
import {
  SpikeEventDataProvider,
  useSpikeDataContext,
} from "../provider/SpikeEventDataProvider";

const analyticsData: ICardAA = {
  id: "card-spike-v2",
  title: `${titlePrefix}SpikeV2`,
  category: "dashboard-card",
  primaryCta: "Go to Spike V2",
  product: { category: "product", divisionCode: "SPIKE" },
};

const Widget = () => {
  const { updateWidgetStatus } = useWidgetProvider();
  const { data, loading, error } = useSpikeDataContext();

  if (loading) {
    return <Loader />;
  }

  if (error || !data) {
    updateWidgetStatus(WidgetStatusCode.FAILURE, [{ ...analyticsData, error }]);
    return <Error />;
  } else {
    updateWidgetStatus(WidgetStatusCode.COMPLETE, [analyticsData]);
  }

  return (
    <TrackingProvider
      onTrackingCapture={trackingProviderCaptureClickCard(analyticsData)}
    >
      <SpikeWidgetContent data={data} />
    </TrackingProvider>
  );
};

export const SpikeWidget = () => (
  <SpikeEventDataProvider>
    <Widget />
  </SpikeEventDataProvider>
);
