import { Box, Card, IconButton, Stack } from "@origin-digital/ods-core";
import { IconChevronRight } from "@origin-digital/ods-icons";
import React from "react";
import { UnstyledButton } from "../UnstyledButton";

type IControlPanelProps = {
  onClick(): void;
};

export const ControlPanel: React.FC<IControlPanelProps> = (props) => {
  const { onClick, children } = props;

  return (
    <Box paddingTop="xsmall" onClick={onClick} data-id="control-panel">
      <UnstyledButton>
        <Card outline paddingYSpecific="medium" paddingXSpecific="medium">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack space="xxsmall">{children}</Stack>
            <Box marginLeft="large">
              <IconButton noIconPadding>
                <IconChevronRight
                  color="neutral"
                  muiProps={{ height: "20px" }}
                  size="xxxsmall"
                />
              </IconButton>
            </Box>
          </Box>
        </Card>
      </UnstyledButton>
    </Box>
  );
};
