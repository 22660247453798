import React from "react";

export const PartlyCloudyIcon = () => (
  <svg
    width="23px"
    height="21px"
    viewBox="0 0 23 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    data-id="weather-icon-partly-cloudy"
  >
    <title>Partly cloudy</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-809.000000, -1408.000000)">
        <g transform="translate(809.000000, 1408.000000)">
          <g id="cloud-partly">
            <g
              transform="translate(0.000000, -0.000000)"
              fill="#FF9F00"
              fillRule="nonzero"
            >
              <path
                d="M19.125,12.9075 C18.5583333,10.104375 16.0333333,8 13,8 C10.5916667,8 8.5,9.3325 7.45833333,11.2825 C4.95,11.5425 3,13.614375 3,16.125 C3,18.814375 5.24166667,21 8,21 L18.8333333,21 C21.1333333,21 23,19.18 23,16.9375 C23,14.7925 21.2916667,13.05375 19.125,12.9075 Z M18.8333333,19.375 L8,19.375 C6.15833333,19.375 4.66666667,17.920625 4.66666667,16.125 C4.66666667,14.329375 6.15833333,12.875 8,12.875 L8.59166667,12.875 C9.14166667,10.998125 10.9,9.625 13,9.625 C15.5333333,9.625 17.5833333,11.62375 17.5833333,14.09375 L17.5833333,14.5 L18.8333333,14.5 C20.2166667,14.5 21.3333333,15.58875 21.3333333,16.9375 C21.3333333,18.28625 20.2166667,19.375 18.8333333,19.375 Z"
                id="Cloud"
              />
              <path
                d="M9,4.09090909 C11.3839355,4.09090909 13.3747965,5.79634523 13.8173483,8.05198286 C13.5496635,8.01754233 13.2769505,8 13,8 C12.7104035,8 12.4253858,8.01926729 12.146222,8.0565587 C11.7556418,6.6879547 10.495326,5.68636364 9,5.68636364 C7.19181818,5.68636364 5.72727273,7.15090909 5.72727273,8.95909091 C5.72727273,9.92577459 6.14586136,10.7942419 6.81176233,11.3932167 C6.22405963,11.5307991 5.67667045,11.7714901 5.19066953,12.0936019 C4.5034642,11.2499394 4.09090909,10.1725132 4.09090909,9 C4.09090909,6.29181818 6.29181818,4.09090909 9,4.09090909 Z M2.45454545,8.18181818 L2.45454545,9.81818182 L1.30562228e-13,9.81818182 L1.30562228e-13,8.18181818 L2.45454545,8.18181818 Z M3.24,2.04545455 L4.71272727,3.51 L3.55090909,4.66363636 L2.08636364,3.19909091 L3.24,2.04545455 Z M14.76,2.04136364 L15.912,3.19254545 L14.4474545,4.65709091 L13.2962727,3.50509091 L14.76,2.04136364 Z M9.81818182,-9.68114477e-14 L9.81818182,2.45454545 L8.18181818,2.45454545 L8.18181818,-9.68114477e-14 L9.81818182,-9.68114477e-14 Z"
                id="Combined-Shape"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
