import React from "react";
import ContentLoader from "react-content-loader";
import { DividerCard, Stack } from "@origin-digital/ods-core";

export const Loader = () => (
  <DividerCard data-id="spike-widget-v2-loading">
    <ContentLoader
      speed={2}
      height={120}
      foregroundColor="#f8f8f8"
      backgroundColor="#f1f1f1"
    >
      <circle cx="18" cy="20" r="19" />
      <rect x="50" y="15" rx="0" ry="0" width="50" height="15" />
      <rect x="0" y="95" rx="0" ry="0" width="110" height="15" />
    </ContentLoader>
    <Stack alignX="right">
      <ContentLoader
        speed={2}
        height={18}
        foregroundColor="#f8f8f8"
        backgroundColor="#f1f1f1"
      >
        <rect x="160" y="0" rx="0" ry="0" width="150" height="24" />
      </ContentLoader>
    </Stack>
  </DividerCard>
);
