import React from "react";
import { Stack, Strong, Text } from "@origin-digital/ods-core";
import { formattedDate } from "../../utils/dateHelper";
import { IEventProps } from "../../types/IEventProps";

export const OptedIn = (props: IEventProps) => {
  const { date } = props.event;

  return (
    <Stack space="small" data-id="spike-widget-v2-opted-in">
      <Text>
        SpikeHour{" "}
        <Strong>
          {formattedDate(date)} {props.event.startTime}
        </Strong>{" "}
        to <Strong>{props.event.endTime}</Strong>.
      </Text>
      <Text>We&apos;ll send you a reminder just before it starts.</Text>
    </Stack>
  );
};
