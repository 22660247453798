import React from "react";
import {
  Column,
  Columns,
  Heading,
  Stack,
  Text,
} from "@origin-digital/ods-core";
import { IconBattery } from "@origin-digital/ods-icons";

export const Header = ({ updatedOn }: { updatedOn?: string }) => (
  <Columns alignY="center" space="xsmall" data-id="community-battery-header">
    <Column width="content">
      <IconBattery size="medium" />
    </Column>
    <Column>
      <Stack space="none">
        <Heading variant="h4" data-id="header-text">
          Community battery
        </Heading>
        {updatedOn && <Byline>Updated {updatedOn}</Byline>}
      </Stack>
    </Column>
  </Columns>
);

const Byline = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text variant="body-small" tone="neutralLight" data-id="byline">
      {children}
    </Text>
  );
};
