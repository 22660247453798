import { Button, Stack, Strong, Text } from "@origin-digital/ods-core";
import React from "react";
import { navTo } from "@origin-digital/event-dispatcher";
import { formattedDate } from "../../utils/dateHelper";
import { IEventProps } from "../../types/IEventProps";
import { useSpikeDataContext } from "../../provider/SpikeEventDataProvider";

export const Invite = (props: IEventProps) => {
  const { date } = props.event;
  const { reload } = useSpikeDataContext();

  const openEventPage = () => {
    navTo.spikeV2Event({ eventId: props.event.id.toString() }).then(reload);
  };

  return (
    <Stack space="small" data-id="spike-widget-v2-invite">
      <Text>
        SpikeHour{" "}
        <Strong>
          {formattedDate(date)} {props.event.startTime}
        </Strong>{" "}
        to <Strong>{props.event.endTime}</Strong>
      </Text>
      <Button variant="outlined" onClick={openEventPage}>
        Join this SpikeHour
      </Button>
    </Stack>
  );
};
