import { Alert, Text, Stack, Card } from "@origin-digital/ods-core";
import React from "react";
import { Header } from "./Header";

export const Error = () => {
  return (
    <Card data-id="spike-widget-v2-error">
      <Stack space="medium" dividers>
        <Header />
        <Alert tone="critical">
          <Text>Sorry, something went wrong. Please try again later.</Text>
        </Alert>
      </Stack>
    </Card>
  );
};
