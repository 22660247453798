// @ts-nocheck
export enum IPageVersion {
    INVITE = 'INVITE',
    TOO_LATE = 'TOO_LATE',
    INVALID = 'INVALID',
    OPT_IN = 'OPT_IN',
    OPT_OUT = 'OPT_OUT',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    UNKNOWN = 'UNKNOWN'
}

