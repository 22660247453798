import React from "react";
import { Stack, Strong, Text } from "@origin-digital/ods-core";

export const Finished = () => {
  return (
    <Stack space="small" data-id="spike-widget-v2-finished">
      <Text>
        SpikeHour has <Strong>ended</Strong>.
      </Text>
      <Text>
        Thanks for joining. We&apos;ll let you know how you did - usually in 2-3
        days.
      </Text>
    </Stack>
  );
};
