import styled from "styled-components";
import { Text } from "@origin-digital/ods-core";

const orangeColor = "#FF9F00";
const greenColor = "#028A00";

const Pill = styled(Text)`
  border-radius: 4px;
  padding: 1px 6px;
  width: fit-content;
`;

export const OrangePill = styled(Pill)`
  background-color: ${orangeColor};
`;

export const GreenPill = styled(Pill)`
  background-color: ${greenColor};
  color: white !important;
`;
