import React from "react";
import { AccountCard } from "@origin-digital/kraken-account";
import { useToggleContext } from "@origin-digital/toggle";
import { WidgetProvider } from "@origin-digital/widget-provider";
import { CardStack } from "../../../components/CardStack";
import { isPresent } from "../../../util/arrayHelper";
import { config } from "../../../config";
import { usePortfolioSummaryQuery } from "../../../dashboard/queries";
import {
  AccountStatus,
  DigitalAccountStatus,
  DigitalAccountType,
  ServiceType,
} from "../../../graphql-types/rx-gateway/globalTypes";
import { UserAccounts_viewer_digital_accounts } from "../../../graphql-types/rx-gateway/UserAccounts";
import { PortfolioSummaryQuery_viewer_kraken_accounts } from "../../../graphql-types/rx-gateway/PortfolioSummaryQuery";
import { getAccountWithStatus, getStatusFilter } from "./accountHelper";
import { sortPortfolio } from "./sortPortfolio";
import { PortfolioLoader } from "./PortfolioLoader";
import { useMultiWidgetAnalytics } from "../../hooks/useMultiWidgetAnalytics";

export interface PortfolioSummaryProps {
  isClosed: boolean;
  digitalAccounts: UserAccounts_viewer_digital_accounts[];
}

export enum DivisionCode {
  ELECTRICITY = "ELECTRICITY",
  NATURAL_GAS = "NATURAL_GAS",
  HOT_WATER = "HOT_WATER",
  LPG = "LPG",
}

const mappedServiceType: {
  [key in ServiceType]: DivisionCode;
} = {
  [ServiceType.ELECTRICITY]: DivisionCode.ELECTRICITY,
  [ServiceType.GAS]: DivisionCode.NATURAL_GAS,
  [ServiceType.EMBEDDED_HOT_WATER]: DivisionCode.HOT_WATER,
  [ServiceType.EMBEDDED_ELECTRICITY]: DivisionCode.ELECTRICITY,
};

const getWidgetId = (accountNumber: string) =>
  `portfolio-widget-${accountNumber}`;

const isNotWithdrawn = (
  account: PortfolioSummaryQuery_viewer_kraken_accounts
) => account.status !== AccountStatus.WITHDRAWN;

export const PortfolioSummary: React.FC<PortfolioSummaryProps> = ({
  isClosed,
  digitalAccounts,
}) => {
  const { toggles } = useToggleContext();
  const { data, loading, error } = usePortfolioSummaryQuery();
  const accounts = data?.viewer?.kraken?.accounts ?? [];
  const accountsWithStatus = sortPortfolio(
    accounts
      .filter(isPresent)
      .filter(isNotWithdrawn)
      .map(getAccountWithStatus)
      .filter(getStatusFilter(isClosed))
  );

  const onStatusChange = useMultiWidgetAnalytics(
    accountsWithStatus,
    (account) => getWidgetId(account.accountNumber)
  );

  // don't render margin when no closed card if we're finished loading without error
  if (accountsWithStatus.length === 0 && !loading && !error) {
    return null;
  }

  const hasAccountsInThisSection = digitalAccounts.some(({ type, status }) => {
    const isKraken = type === DigitalAccountType.KRAKEN;
    const showInThisSection = isClosed
      ? status === DigitalAccountStatus.CLOSED
      : status !== DigitalAccountStatus.CLOSED;

    return isKraken && showInThisSection;
  });

  const showLoader = loading || error;

  // don't show loader for section if we think there are no accounts loading
  if (showLoader && !hasAccountsInThisSection) {
    return null;
  }

  return (
    <CardStack>
      {showLoader ? (
        <PortfolioLoader isLoading={loading} />
      ) : (
        accountsWithStatus.map(({ accountNumber, serviceStatus, services }) => {
          let serviceType;

          if (services[0].type) {
            serviceType = mappedServiceType[services[0].type];
          }

          return (
            <WidgetProvider key={accountNumber} onStatusChange={onStatusChange}>
              <AccountCard
                accountNumber={accountNumber}
                env={config.env}
                hideDirectDebitLink
                id={getWidgetId(accountNumber)}
                accountStatus={serviceStatus}
                toggles={toggles}
                serviceType={serviceType}
              />
            </WidgetProvider>
          );
        })
      )}
    </CardStack>
  );
};
