import React from "react";
import {
  Button,
  Column,
  Columns,
  Dialog,
  Heading,
  Inline,
  Stack,
  Text,
} from "@origin-digital/ods-core";
import { IconSolar } from "@origin-digital/ods-icons";
import { HollowBatteryIcon } from "../Icons/HollowBatteryIcon";
import { PowerlineIcon } from "../Icons/PowerlineIcon";

export const InfoModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog
      id="community-battery-info-modal"
      open={open}
      title=""
      onClose={onClose}
      noFullScreenOnMobile
    >
      <Stack space="xlarge" alignX="center">
        <Stack space="small" alignX="center">
          <Stack space="xsmall" alignX="center">
            <IconSolar color="positive" size="xxxsmall" />
            <Heading variant="h3" weight="medium" align="center">
              Solar export
            </Heading>
          </Stack>
          <Text align="center">
            Here’s a breakdown of energy generated by your solar panels
          </Text>
        </Stack>
        <Columns space={["large", "xlarge"]} collapseBelow="sm">
          <Column>
            <Stack space="xsmall" alignX={["center", "left"]}>
              <Inline space="xxsmall" alignX={["center", "left"]}>
                <HollowBatteryIcon color="positive" />
                <Heading variant="h4">To battery</Heading>
              </Inline>
              <Text align={["center", "left"]}>
                Stored energy to use when the sun isn’t shining
              </Text>
            </Stack>
          </Column>
          <Column>
            <Stack space="xsmall" alignX={["center", "left"]}>
              <Inline space="xxsmall" alignX={["center", "left"]}>
                <PowerlineIcon />
                <Heading variant="h4">To grid</Heading>
              </Inline>
              <Text align={["center", "left"]}>
                Excess energy that’s fed back to the grid
              </Text>
            </Stack>
          </Column>
        </Columns>
        <Button onClick={onClose} noTextPadding>
          Done
        </Button>
      </Stack>
    </Dialog>
  );
};
