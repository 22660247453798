import { useEffect, useState } from "react";
import { getDashboard } from "../service/spikeService";
import { IDashboardPageData } from "../contract";

export const useSpikeEventData = () => {
  const [data, setData] = useState<IDashboardPageData>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();

  const reload = () => {
    getDashboard()
      .then((result) => setData(result))
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  };

  useEffect(reload, []);

  return { data, loading, error, reload };
};
