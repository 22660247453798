import React from "react";
import { IEventProps } from "../../types/IEventProps";
import { Stack, Text } from "@origin-digital/ods-core";

export const Failure = (props: IEventProps) => {
  const { date, actualUsage, postEventBaseline } = props.event;

  return (
    <Stack space="small" data-id="spike-widget-v2-failure">
      <Text tone="neutralDark" weight="medium">
        Try again next SpikeHour
      </Text>
      <Text>
        During the {date} SpikeHour you used {actualUsage} kWh which is more
        than your energy goal of {postEventBaseline} kWh. We&apos;ll let you
        know when the next SpikeHour rolls around.
      </Text>
    </Stack>
  );
};
