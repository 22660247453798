import React from "react";
import { IEventProps } from "../../types/IEventProps";
import { Button, Stack, Strong, Text } from "@origin-digital/ods-core";
import { navTo } from "@origin-digital/event-dispatcher";
import { useSpikeDataContext } from "../../provider/SpikeEventDataProvider";

export const InProgress = (props: IEventProps) => {
  const { reload } = useSpikeDataContext();

  const openEventPage = () => {
    navTo.spikeV2Event({ eventId: props.event.id.toString() }).then(reload);
  };

  return (
    <Stack space="small" data-id="spike-widget-v2-in-progress">
      <Text>
        SpikeHour ends at <Strong>{props.event.endTime}</Strong>
      </Text>
      <Text>
        Stay below your energy goal and you&apos;ll earn one Energy Happy Hour.
      </Text>
      <Button variant="text" size="small" noTextPadding onClick={openEventPage}>
        Tips to reduce energy
      </Button>
    </Stack>
  );
};
