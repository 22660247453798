import React from "react";
import { Column, Columns, Heading, Inline } from "@origin-digital/ods-core";
import { IconSpike } from "@origin-digital/ods-icons";

export const Header = () => (
  <Columns space="small" alignY="center">
    <Column>
      <Inline space="small" alignY="center">
        <IconSpike size="medium" />
        <Heading variant="h4" data-id="spike-heading-v2">
          Spike
        </Heading>
      </Inline>
    </Column>
  </Columns>
);
