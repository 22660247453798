import {
  Alert,
  Button,
  Dialog,
  Heading,
  Stack,
  Text,
} from "@origin-digital/ods-core";
import React from "react";
import { BatteryOffIcon } from "../../Icons/BatteryOffIcon";
import { IControlDialogProps } from "./UpdateControlDialog";

export const DeactivateControlDialog: React.FC<IControlDialogProps> = (
  props
) => {
  const { open, loading, onClose, onConfirm } = props;

  return (
    <Dialog
      open={open}
      id="deactivate-control-dialog"
      data-id="deactivate-control-dialog"
      title=""
      onClose={onClose}
    >
      <Stack space="xlarge" alignX="center">
        <BatteryOffIcon />
        <Stack space="medium">
          <Heading variant="h3" weight="medium" align="center">
            Snooze Battery Maximiser?
          </Heading>
          <Text align="center">
            For 24 hours, we'll temporarily pause the energy transfer to and
            from the grid. Your battery will still continue to power your home.
            You can also end it earlier at any time!
          </Text>
          <Alert tone="caution">
            By snoozing, your battery won't be topped up using energy from the
            grid and excess energy won't be exported - this means less savings
            for you. It takes up to 5 minutes for snoozing to start.
          </Alert>
        </Stack>

        <Stack space="xlarge" alignX="center">
          <Button
            noTextPadding
            spinner={loading}
            onClick={onConfirm}
            data-id="confirm"
          >
            Confirm
          </Button>
          <Button
            variant="text"
            size="small"
            noTextPadding
            onClick={onClose}
            data-id="cancel"
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
