import React from "react";

import { parseMarkdownEvent } from "@origin-digital/event-dispatcher";
import {
  LinkComponentProps,
  LinkComponentType,
} from "@origin-digital/ods-types";
import {
  OriginThemeProvider,
  TrackingProvider,
} from "@origin-digital/ods-core";
import { trackingProviderCaptureClick } from "@origin-digital/reporting-client";
import {
  createApolloClient,
  getKraxiClientConfigPreset,
  KraxiProvider,
} from "@origin-digital/kraxi";
import { ToggleContextProvider } from "@origin-digital/toggle";

import { AppContextProvider } from "src/context/AppContext";
import { ErrorWrapper } from "src/components/ErrorWrapper";
import { DashboardPage } from "src/dashboard/DashboardPage";

import { config } from "./config";
import { DashboardAppMetricsProvider } from "./context/DashboardAppMetricsProvider";
import { GlobalStyles } from "./global-styles";

export const MeshLink = React.forwardRef<any, LinkComponentProps>(
  (props, ref) => {
    const markdownEvent = parseMarkdownEvent(props.href);
    const href = markdownEvent.href;
    const onClick = markdownEvent.onClick;

    return (
      <a
        ref={ref}
        {...props}
        href={href}
        onClick={onClick ? onClick : props.onClick}
      >
        {props.children}
      </a>
    );
  }
);

export default () => (
  <OriginThemeProvider linkComponent={MeshLink as LinkComponentType}>
    <TrackingProvider onTrackingCapture={trackingProviderCaptureClick}>
      <ToggleContextProvider toggles={config.toggles} appName={config.appName}>
        <AppContextProvider>
          <KraxiProvider
            client={createApolloClient(
              getKraxiClientConfigPreset("gateway", config.env)
            )}
          >
            <ErrorWrapper>
              <DashboardAppMetricsProvider>
                <GlobalStyles />
                <DashboardPage />
              </DashboardAppMetricsProvider>
            </ErrorWrapper>
          </KraxiProvider>
        </AppContextProvider>
      </ToggleContextProvider>
    </TrackingProvider>
  </OriginThemeProvider>
);
