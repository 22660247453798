import { Card, Box, Text, CardProps } from "@origin-digital/ods-core";
import {
  AlertTones,
  TypographyWeightVariants,
} from "@origin-digital/ods-types";
import React from "react";
import { useTheme } from "styled-components";

export interface StatusCardProps extends CardProps {
  tone?: Exclude<AlertTones, "promoteB" | "promoteC">;
  weight?: Exclude<TypographyWeightVariants, "bold">;
  children: JSX.Element;
  title?: string;
}

export const StatusCard = ({
  title,
  tone,
  weight = "medium",
  children,
  ...rest
}: StatusCardProps) => {
  const theme = useTheme() as any;

  return (
    <Card paddingXSpecific="none" paddingYSpecific="none" {...rest}>
      <Box overflow="hidden" borderRadius="medium">
        {title && (
          <Box
            backgroundColor={theme.palette.getColorVariantCSSColor(tone)}
            paddingX="xxsmall"
            style={{ paddingTop: "2px", paddingBottom: "2px" }}
          >
            <Text
              variant="body-small"
              weight={weight}
              tone={tone === "caution" ? "neutral" : "white"}
              align="center"
            >
              {title}
            </Text>
          </Box>
        )}

        {children}
      </Box>
    </Card>
  );
};
