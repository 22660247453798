// @ts-nocheck
export enum IWidgetVersion {
    NO_EVENTS = 'NO_EVENTS',
    INVITE = 'INVITE',
    OPTED_IN = 'OPTED_IN',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE'
}

